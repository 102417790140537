export default {
  statusBar: {
    backgroundColor: {
      // --bg-surface
      default: '#ffffff',

      // --bg-surface-low
      low: '#f6f6f6',
    },

    style: 'LIGHT',
  },
};
