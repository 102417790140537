export default {
  statusBar: {
    backgroundColor: {
      // --bg-surface
      default: '#262622',

      // --bg-surface-low
      low: '#1b1b18',
    },

    style: 'DARK',
  },
};
