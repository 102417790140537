import './scss/theme/white/base.scss';
import './scss/theme/dark/base.scss';

import './scss/theme/silver/base.scss';
import './scss/theme/butter/base.scss';
import './scss/theme/black/base.scss';

import './scss/default.scss';

import StartApp from './start';

StartApp('vite');
