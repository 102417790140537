export default {
  statusBar: {
    backgroundColor: {
      // --bg-surface
      default: '#f2f2f2',

      // --bg-surface-low
      low: '#e8e8e8',
    },

    style: 'LIGHT',
  },
};
