export default {
  statusBar: {
    backgroundColor: {
      // --bg-surface
      default: '#1d1b1b',

      // --bg-surface-low
      low: '#212121',
    },

    style: 'DARK',
  },
};
